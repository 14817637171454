const bookingPayment: any = {
  en: {
    pay: "Pay and book",
    book: "Book",
    previous: "Previous",
    bookingInProgress: "Booking in progress",
    ticketSelection: "Ticket selection",
    payOneCard: "Pay with one card",
    payManyCards: "Pay with many cards",
    activatePromoCode: "Activate",
    promoCode: "Promo code",
    extraInfosTitle: "Informations required for booking",
    readAndAccept: "I have read and accept the",
    accept: "I accept the",
    processingConditions: "conditions de traitement des données conformes au RGPD",
    salesConditions: "general conditions of sales",

    GDPRNoticesTitle: "GDPR Compliant Information Notices",
    GDPRNoticesContent: `The Personal Data collected is processed by HOPLEISURE SAS (SIREN: 952 553 139) in its capacity as data controller and used to guarantee the traceability of the reservation and the associated transaction between the user and the company operating the experience, in the name and on behalf of which HOPLEISURE SAS has made this reservation technology available.

The information collected may be used only by the company operating the experience for accounting or sales canvassing purposes. HOPLEISURE has no rights over the Personal Data.

In accordance with Regulation (EU) 2016/679 of 27 April 2016 on the protection of personal data and the amended Data Protection Act of 6 January 1978, you have the right to access, rectify, erase, transfer and limit the processing of your personal data, as well as the right to communicate instructions on the fate of your data after your death. You may also, for legitimate reasons, object to the processing of your personal data. You can exercise your rights by contacting Ricardo Vasques at the following address: ricardo.vasques@hopleisure.com or at the following postal address: 25 RUE DES LAITIERES 94300 VINCENNES

For more information on the Processing of your Personal Data by HOPLEISURE SAS, please consult our privacy policy available on our website at the following address: https://hopleisure.com/politique-de-confidentialite

Anonymized transactional data not falling under Personal Data will however be usable by HOPLEISURE SAS including for commercial or statistical purposes.`,
    salesConditionsTitle: "General conditions of sale",
    salesConditionsContent: `The sales contract between the operating company in the name and on behalf of which HOPLEISURE has developed this reservation technology and the user is concluded as soon as the user makes the payment on the tools made available by HOPLEISURE SAS in partnership with https://stripe.com/fr.

All the information necessary for the conclusion of the sales contract is made available to the user by the reservation tool (identity of the seller, date, time, price of the reservation, co-contractor, purpose of the purchase and conditions of reimbursement and cancellation, etc.) as well as by the website on which the purchase was made. The buyer acknowledges by checking the box "I accept the general conditions of sale" having had sufficient information at his disposal to make an informed purchasing decision.`,
    acceptConditions: "I HAVE READ AND ACCEPT THESE CONDITIONS",
    error: {
      paymentProblem: "Payment failed",
      promoCodeNotAvailable:
        "The quantity of tickets selected is greater than the quantity of tickets available for this promo code",
      MIN_BASKET_NOT_REACHED: "Promo code requires a minimum basket of {{value}} euros!",
      MAX_TICKETS_REACHED: "Promo code exhausted! Check if there are others available.",
      MAX_AMOUNT_REACHED: "Promo code exhausted! Check if there are others available.",
      DISCOUNT_DATE_RANGE_INVALID: "Promo code inactive on the selected date!",
      LAST_DAY_DISCOUNT_NOT_REACHED:
        "This last minute discount is available only {{value}} hours prior to the selected time slot.",
      DISCOUNT_NOT_FOUND: "This promo code is not active for the selected activity.",
    },
    form: {
      name: {
        label: "Your firstname and lastname",
        error: "Complete name is required",
      },
      email: {
        label: "Your email address",
        error: "Email address is required",
      },
      phoneNumber: {
        label: "Phone number",
        error: "Phone number is required",
      },
      extraInfo: {
        error: {
          empty: "Answer is required",
          min: "Answer should be at least 3 characters",
        },
      },
      checkboxes: {
        error: "Please accept the T&Cs and the personal data processing policy.",
      },
    },
    signInGoogle: "Sign in with Google",
    signOutGoogle: "Sign out of Google",
    successGoogleLogin: "{{name}}, you are logged in with your Google account",
  },
  fr: {
    pay: "Payer et réserver",
    book: "Réserver",
    previous: "Précédent",
    bookingInProgress: "Réservation en cours",
    ticketSelection: "Sélection de votre billet",
    payOneCard: "Paiement une carte",
    payManyCards: "Paiement plusieurs cartes",
    activatePromoCode: "Activer",
    promoCode: "Code promo",
    extraInfosTitle: "Informations requises pour la réservation",
    readAndAccept: "J'ai lu et accepte les",
    accept: "J'accepte les ",
    processingConditions: "conditions de traitement des données conformes au RGPD",
    salesConditions: "conditions générales de vente",
    GDPRNoticesTitle: "Mentions d’informations conformes au RGPD",
    GDPRNoticesContent: `Les Données à caractère personnel collectées sont traitées par HOPLEISURE SAS (SIREN : 952 553 139) en sa qualité de responsable de traitement et utilisées afin de garantir la traçabilité de la réservation et de la transaction associée entre l’utilisateur et la société exploitante de l’expérience, au nom et pour le compte de laquelle HOPLEISURE SAS a mis à disposition cette technologie de réservation. 

Les informations collectées pourront être utilisées uniquement par la société exploitante de l’expérience uniquement à des fins de tenue comptable ou de démarchage commerciale. HOPLEISURE ne dispose d’aucun droit sur les Données à caractère personneL.
 
Conformément au Règlement (UE) 2016/679 du 27 avril 2016 sur la protection des Données à caractère personnel et à la loi « informatique et libertés » du 6 janvier 1978 modifiée, vous bénéficiez d’un droit d’accès, de rectification, d’effacement, de portabilité et de limitation du Traitement des Données vous concernant ainsi que du droit de communiquer des directives sur le sort de vos Données après votre mort. Vous pouvez également, pour des motifs légitimes, vous opposer au Traitement des Données vous concernant.  
 
Vous pouvez exercer vos droits en contactant Ricardo Vasques à l’adresse suivante : ricardo.vasques@hopleisure.com ou à l’adresse postale suivante : 25 RUE DES LAITIERES 94300 VINCENNES
 
Pour plus d’informations sur le Traitement de vos Données à caractère personnel par HOPLEISURE SAS, veuillez consulter notre politique de confidentialité disponible sur notre site internet à l’adresse suivante : https://hopleisure.com/politique-de-confidentialite

Les données transactionnelles anonymisées ne relevant pas de Données à caractère personnel seront en revanche exploitables par HOPLEISURE SAS y compris à des fins commerciales ou statistiques.`,
    salesConditionsTitle: "Conditions générales de vente",
    salesConditionsContent: `Le contrat de vente entre la société exploitante au nom et pour le compte de laquelle HOPLEISURE a développé cette technologie de prise de réservation et l’utilisateur est conclu dès que l’utilisateur procède au paiement sur les outils mis à disposition par HOPLEISURE SAS en partenariat avec https://stripe.com/fr.
 
Toutes les informations nécessaires à la conclusion du contrat de vente sont mises à disposition de l’utilisateur par l’outil de réservation (identité du vendeur, date, heure, prix de la réservation, cocontractant, objet de l’achat et conditions de remboursement et d’annulation…) ainsi que par le site internet sur lequel l’achat a été réalisé. L’acheteur reconnait en cochant la case “J’accepte les conditions générales de vente” avoir eu à sa disposition suffisamment d’informations pour prendre une décision d’achat éclairée.`,
    acceptConditions: "J’AI LU ET J’ACCEPTE CES CONDITIONS",
    paymentSuccessfull: {
      title: "Merci {{name}}, réservation finalisée avec succès !",
      description:
        "Vous recevrez rapidement une confirmation de réservation par email à : {{email}}",
    },
    error: {
      paymentProblem: "Le paiement a échoué",
      promoCodeNotAvailable:
        "La quantité de billets sélectionnée est supérieure à la quantité de billets disponibles pour ce code promo",
      MIN_BASKET_NOT_REACHED: "Code promo soumis à un panier minimal de {{value}} euros !",
      MAX_TICKETS_REACHED: "Code promo épuisé! Renseignez-vous, il en existe peut-être d'autres.",
      MAX_AMOUNT_REACHED: "Code promo épuisé! Renseignez-vous, il en existe peut-être d'autres.",
      DISCOUNT_DATE_RANGE_INVALID: "Code promo inactif à la date sélectionnée !",
      LAST_DAY_DISCOUNT_NOT_REACHED:
        "Cette promotion de dernière minute est disponible seulement {{value}} heures avant le créneau réservé.",
      DISCOUNT_NOT_FOUND: "Ce code promo n'est pas actif pour l'activité considérée.",
    },
    form: {
      name: {
        label: "Votre nom et prénom",
        error: "Nom et prénom sont requis",
      },
      email: {
        label: "Votre adresse email",
        error: "Adresse email est requise",
      },
      phoneNumber: {
        label: "Numéro de téléphone",
        error: "Numéro de téléphone est requis",
      },
      extraInfo: {
        error: {
          empty: "La réponse est obligatoire",
          min: "La réponse doit comporter au moins 3 caractères",
        },
      },
      checkboxes: {
        error: "Merci d'accepter les CGV et la politique de traitement des données personnelles",
      },
    },
    signInGoogle: "Se connecter avec Google",
    signOutGoogle: "Me déconnecter de Google",
    successGoogleLogin: "{{name}}, vous êtes connecté avec votre compte Google",
  },
  es: {
    pay: "Pagar y reservar",
    book: "Reservar",
    previous: "Anterior",
    bookingInProgress: "Reserva en progreso",
    ticketSelection: "Selección de boletos",
    payOneCard: "Pagar con una tarjeta",
    payManyCards: "Pagar con varias tarjetas",
    activatePromoCode: "Activar",
    promoCode: "Código promocional",
    extraInfosTitle: "Información requerida para la reserva",
    readAndAccept: "He leído y acepto los",
    accept: "Acepto los",
    processingConditions: "condiciones de procesamiento de datos conforme al RGPD",
    salesConditions: "condiciones generales de venta",
    GDPRNoticesTitle: "Avisos de información conforme al RGPD",
    GDPRNoticesContent: `Los datos personales recolectados son procesados por HOPLEISURE SAS (SIREN: 952 553 139) en su calidad de responsable del tratamiento y utilizados para garantizar la trazabilidad de la reserva y la transacción asociada entre el usuario y la empresa que opera la experiencia, en nombre y por cuenta de la cual HOPLEISURE SAS ha puesto a disposición esta tecnología de reserva.

La información recolectada solo podrá ser utilizada por la empresa que opera la experiencia para fines contables o de prospección comercial. HOPLEISURE no tiene derechos sobre los datos personales.

De acuerdo con el Reglamento (UE) 2016/679 del 27 de abril de 2016 sobre la protección de datos personales y la Ley de Protección de Datos del 6 de enero de 1978 modificada, usted tiene el derecho de acceder, rectificar, borrar, transferir y limitar el procesamiento de sus datos personales, así como el derecho de comunicar instrucciones sobre el destino de sus datos tras su fallecimiento. También puede, por motivos legítimos, oponerse al tratamiento de sus datos personales. Puede ejercer sus derechos poniéndose en contacto con Ricardo Vasques en la siguiente dirección: ricardo.vasques@hopleisure.com o en la siguiente dirección postal: 25 RUE DES LAITIERES 94300 VINCENNES

Para obtener más información sobre el tratamiento de sus datos personales por parte de HOPLEISURE SAS, consulte nuestra política de privacidad disponible en nuestro sitio web en la siguiente dirección: https://hopleisure.com/politique-de-confidentialite

Los datos transaccionales anonimizados que no sean datos personales serán, sin embargo, utilizables por HOPLEISURE SAS, incluso para fines comerciales o estadísticos.`,
    salesConditionsTitle: "Condiciones generales de venta",
    salesConditionsContent: `El contrato de venta entre la empresa operadora en nombre y por cuenta de la cual HOPLEISURE ha desarrollado esta tecnología de reserva y el usuario se celebra tan pronto como el usuario realiza el pago en las herramientas proporcionadas por HOPLEISURE SAS en asociación con https://stripe.com/fr.

Toda la información necesaria para la celebración del contrato de venta está a disposición del usuario a través de la herramienta de reserva (identidad del vendedor, fecha, hora, precio de la reserva, contratante, propósito de la compra y condiciones de reembolso y cancelación, etc.) así como en el sitio web donde se realizó la compra. El comprador reconoce, al marcar la casilla "Acepto las condiciones generales de venta", que ha tenido suficiente información a su disposición para tomar una decisión de compra informada.`,
    acceptConditions: "HE LEÍDO Y ACEPTADO ESTAS CONDICIONES",
    paymentSuccessfull: {
      title: "Gracias {{name}}, ¡reserva completada con éxito!",
      description:
        "Recibirá rápidamente una confirmación de su reserva por correo electrónico a: {{email}}",
    },
    error: {
      paymentProblem: "El pago ha fallado",
      promoCodeNotAvailable:
        "La cantidad de boletos seleccionada es mayor que la cantidad de boletos disponibles para este código promocional",
      MIN_BASKET_NOT_REACHED: "¡El código promocional requiere un mínimo de {{value}} euros!",
      MAX_TICKETS_REACHED: "¡El código promocional se agotó! Verifique si hay otros disponibles.",
      MAX_AMOUNT_REACHED: "¡El código promocional se agotó! Verifique si hay otros disponibles.",
      DISCOUNT_DATE_RANGE_INVALID:
        "¡El código promocional no es válido para la fecha seleccionada!",
      LAST_DAY_DISCOUNT_NOT_REACHED:
        "¡Este descuento de última hora solo está disponible {{value}} horas antes del horario seleccionado!",
      DISCOUNT_NOT_FOUND: "Este código promocional no está activo para la actividad seleccionada.",
    },
    form: {
      name: {
        label: "Tu nombre y apellido",
        error: "Se requiere el nombre completo",
      },
      email: {
        label: "Tu dirección de correo electrónico",
        error: "Se requiere la dirección de correo electrónico",
      },
      phoneNumber: {
        label: "Número de teléfono",
        error: "Se requiere el número de teléfono",
      },
      extraInfo: {
        error: {
          empty: "Se requiere una respuesta",
          min: "La respuesta debe tener al menos 3 caracteres",
        },
      },
      checkboxes: {
        error: "Por favor, acepte los T&C y la política de procesamiento de datos personales.",
      },
    },
    signInGoogle: "Iniciar sesión con Google",
    signOutGoogle: "Cerrar sesión de Google",
    successGoogleLogin: "{{name}}, has iniciado sesión con tu cuenta de Google",
  },
}

export default bookingPayment
