import { z } from "zod"
import { dateableWithTimeString, getAuthorizedConfig } from "../../helpers/helpers"
import axios from "axios"
import { BookingSourceEnum, BookingStatusEnum, phoneRegex } from "../../helpers/constants"
import { extraInfo } from "components/PaymentForm/PaymentForm.types"

const visitorInfos = z.object({
  fullName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
})

const bookingItem = z.object({
  id: z.number().optional(),
  activity_id: z.number(),
  numberOfUnit: z.number(),
  priceName: z.string(),
  price: z.number(),
  from: dateableWithTimeString,
  to: dateableWithTimeString,
  status: z.enum([
    BookingStatusEnum.TO_PAY,
    BookingStatusEnum.BOOKED,
    BookingStatusEnum.CANCELED,
    BookingStatusEnum.PENDING,
  ]),
})

export type BookingItem = z.infer<typeof bookingItem>

// ----------
export const postBookingInput = z.object({
  params: z.object({
    activityId: z.string(),
  }),
  body: z.object({
    visitorInfos: visitorInfos,
    bookings: z.array(bookingItem),
    source: z.enum([BookingSourceEnum.WIDGET, BookingSourceEnum.HOPLEISURE]),
    discountCode: z.string().optional(),
    extraInfos: z.array(extraInfo).optional(),
    platformId: z.number().optional().nullable(),
  }),
})

export type PostBookingInput = z.infer<typeof postBookingInput>

// postBooking
export async function postBooking(
  activityId: number,
  data: PostBookingInput["body"],
): Promise<BookingItem[]> {
  const config = getAuthorizedConfig("POST")
  config["url"] = `${process.env.REACT_APP_API_URL}/bookings/activity/${activityId}`
  config["data"] = data
  const response = await axios.request(config)
  const result = response.data
  return result
}

export async function confirmPaymentWithoutStripeInCaseNoPrice(bookingIds: number[]) {
  const config = getAuthorizedConfig("POST")
  config["url"] = `${process.env.REACT_APP_API_URL}/bookings/confirm-order`
  config["data"] = { bookingIds }
  const response = await axios.request(config)
  const result = response.data
  return result
}

// ----------
export const postPriceBookingSchema = z.object({
  numberOfUnit: z.number(),
  priceName: z.string(),
  status: z.nativeEnum(BookingStatusEnum).optional(),
})

export const postBookingsPriceInput = z.object({
  body: z.object({
    activityId: z.number(),
    bookings: z.array(postPriceBookingSchema),
    discountCode: z.string().optional(),
  }),
})

export type PostBookingsPriceInput = z.infer<typeof postBookingsPriceInput>

export type PostPriceBookingOutput = {
  price: number
}
// getBookingsPrice
export async function getBookingsPrice(
  activityId: number,
  bookings: PostBookingsPriceInput["body"]["bookings"],
  discountCode: PostBookingsPriceInput["body"]["discountCode"],
): Promise<PostPriceBookingOutput> {
  const config = getAuthorizedConfig("POST")
  config["url"] = `${process.env.REACT_APP_API_URL}/bookings/price/`
  config["data"] = { activityId, bookings, discountCode }
  const response = await axios.request(config)
  const result = response.data
  return result
}

// Group Bookings
export const groupBookingsForm = z.object({
  activityId: z.string().min(1),
  fullName: z.string().min(1),
  email: z.string().email().min(1),
  phoneNumber: z.string().min(1).regex(phoneRegex),
  dateReservation: z.string().min(1),
  messages: z.string().min(1),
  checkboxes: z.array(z.string().nullable()).min(1),
})

const groupBookingsPayload = z.object({
  visitorInfos: visitorInfos,
  activityId: z.number(),
  widgetId: z.number(),
  dateReservation: z.string().min(1),
  messages: z.string().min(1),
})

export type GroupBookingsForm = z.infer<typeof groupBookingsForm>
export type GroupBookingsPayload = z.infer<typeof groupBookingsPayload>

export async function postGroupBookings(data: GroupBookingsPayload) {
  const config = getAuthorizedConfig("POST")
  config["url"] = `${process.env.REACT_APP_API_URL}/clients/group/quotes`
  config["data"] = data
  const response = await axios.request(config)
  const result = response.data
  return result
}
