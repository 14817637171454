import React, { Suspense, useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { getActivities, getPlateform, getWidgetById } from "backend/api/activities"
import { Order } from "types/order.type"
import { getQueryParams, googleFontLoader, isArrayEmpty } from "helpers/helpers"
import { paymentAccountVerification } from "backend/api/stripeConnect"
import Skeletons from "components/general/Skeletons"
import { useTranslation } from "react-i18next"
import { isEmpty, isNumber, isString } from "lodash"
import { WidgetContainer } from "./style"
import { WidgetType } from "types/widget.type"
import { useDispatch } from "react-redux"
import {
  ACTIVITY_UPLOAD_SUCCESS,
  CLIENT_ID_UPLOAD_SUCCESS,
  CLIENT_PAYMENT_ACCOUNT_UPLOAD_SUCCESS,
  WIDGET_UPLOAD_SUCCESS,
} from "constants/widgetConstants"
import { useSelector } from "react-redux"
import { RootState } from "store"
import GroupBookings from "./GroupBookings"
import useWidget from "hooks/useWidget"
import { PLATFORM_LOAD_SUCCESS } from "constants/platformConstants"
import ManageBookings from "./ManageBookings"
const DateTicketPicker = React.lazy(() => import("widget/DateTicketPicker"))
const ActivityList = React.lazy(() => import("widget/ActivitiyList/ActivityList"))
const Payment = React.lazy(() => import("widget/Payment"))
const LanguageSelector = React.lazy(() => import("components/general/LanguageSelector"))
const Poweredby = React.lazy(() => import("components/poweredby/Poweredby"))

export type Step =
  | "activityList"
  | "dateTicketPicker"
  | "payment"
  | "groupBooking"
  | "cancelBooking"

const LandingPage = () => {
  const { t } = useTranslation("general")
  const urlParams = getQueryParams()
  const widgetIdParam: string | null = urlParams.get("widgetId")
  const { CLIENT_ID } = useSelector((state: RootState) => state.client)
  const singleActivityPlatform = useSelector((state: RootState) => state.singleActivityPlatform)
  const [currentStep, setCurrentStep] = useState<Step>("activityList")
  const [order, setOrder] = useState<Order>({
    selected_activities: [],
    bookings: [],
  })
  const [pinnedDiscount, setPinnedDiscount] = useState<WidgetType | undefined>()
  const { widget, isSingleActivity } = useWidget()
  const dispatch = useDispatch()
  const widgetRef = useRef(null)

  const widgetId = isString(widgetIdParam) ? parseInt(widgetIdParam) : null

  if (isNumber(widgetId)) {
    useQuery({
      queryKey: [`get-widget-${widgetId}`],
      queryFn: () => getWidgetById(widgetId),
      staleTime: 60000,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess(data) {
        setPinnedDiscount(data)
        dispatch({ type: ACTIVITY_UPLOAD_SUCCESS, payload: data.activities })
        dispatch({ type: WIDGET_UPLOAD_SUCCESS, payload: data.widgets })
        dispatch({ type: CLIENT_ID_UPLOAD_SUCCESS, payload: String(data.widgets.clientId) })
      },
    })
  }
  const handleScrollToWidget = () => {
    const widgetElement = widgetRef.current
    if (widgetElement) {
      // @ts-expect-error igonore typescript errors
      const widgetTop = widgetElement.getBoundingClientRect().top
      if (widgetTop > 50) {
        // Scroll if y postition > 50x diff
        // @ts-expect-error igonore typescript errors
        widgetElement.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  const { data: activities, isLoading: isActivitiesLoading } = useQuery({
    queryKey: [`get-all-actitivities-${CLIENT_ID}`],
    enabled: !!CLIENT_ID, // Only run query if CLIENT_ID is not an empty string
    queryFn: () => getActivities(CLIENT_ID),
    staleTime: 60000,
    refetchOnWindowFocus: false,
    retry: false,
  })

  useQuery({
    queryKey: [`get-platform-data`],
    enabled: isNumber(widget?.platformId),
    queryFn: () => getPlateform(widget?.platformId),
    onSuccess(data) {
      dispatch({ type: PLATFORM_LOAD_SUCCESS, payload: data })
    },
    staleTime: 60000,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const openedActivities = useMemo(() => {
    const filteredActivities = isEmpty(widget)
      ? activities
      : activities?.filter((activity) => {
          return (
            !activity.informations.isDeleted &&
            activity.informations.isEnabled &&
            widget.activitiesIds?.some((activityId: number) => activityId === activity.id)
          )
        })
    if (isSingleActivity) {
      return filteredActivities
    }
    return filteredActivities?.filter((activity) => {
      const widgetChannel = activity.channels.find((channel) => channel.name === "widget")
      return widgetChannel?.status === "OPENED"
    })
  }, [activities, widget, isSingleActivity])

  useEffect(() => {
    const savedFontFamily = widget?.style?.fontFamily
    if (savedFontFamily) {
      requestAnimationFrame(() => {
        googleFontLoader(savedFontFamily)
      })
    }
  }, [widget])

  useEffect(() => {
    if (isSingleActivity && currentStep === "activityList" && !isArrayEmpty(openedActivities)) {
      setOrder({
        bookings: [],
        selected_activities: openedActivities as [],
      })
      setCurrentStep("dateTicketPicker")
    }
  }, [widget, openedActivities, currentStep, setOrder])

  const { data: stripeVerification, isLoading: isStripeVerificationLoading } = useQuery({
    queryKey: [`strip-verification-${CLIENT_ID}`],
    enabled: !!CLIENT_ID,
    queryFn: () => paymentAccountVerification(CLIENT_ID),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      dispatch({
        type: CLIENT_PAYMENT_ACCOUNT_UPLOAD_SUCCESS,
        payload: data,
      })
    },
  })

  const handleChangeStep = (step: Step) => {
    const handleBackFromPayment = () => {
      setOrder((prevOrder) => {
        return { ...prevOrder, bookings: [] }
      })
    }
    setCurrentStep(step)
    if (step === "dateTicketPicker" || step === "activityList") {
      handleBackFromPayment()
    }
  }

  if (isActivitiesLoading || isStripeVerificationLoading) {
    return (
      <WidgetContainer ref={widgetRef}>
        <div id="google_translate_element" className="hidden" />
        <Skeletons />
      </WidgetContainer>
    )
  }

  if (!stripeVerification?.accountCompleted || !openedActivities) {
    return (
      <WidgetContainer ref={widgetRef}>
        <div id="google_translate_element" className="hidden" />
        <h1 className="notranslate">{t("badAccountConfig")}</h1>
      </WidgetContainer>
    )
  }

  return (
    <WidgetContainer
      id="hopleisure-widget"
      ref={widgetRef}
      onClick={handleScrollToWidget}
      fontFamily={widget?.style?.fontFamily}
      style={{
        backgroundColor: widget?.style?.colors?.widgetBackgroundColor,
        borderRadius: isSingleActivity ? "15px" : "",
        height: "100vh",
      }}
    >
      <div id="google_translate_element" className="hidden" />
      <Suspense fallback={<Skeletons />}>
        <LanguageSelector />
        {currentStep === "activityList" && (
          <ActivityList
            activities={openedActivities}
            order={order}
            setOrder={setOrder}
            handleChangeStep={handleChangeStep}
            pinnedDiscount={pinnedDiscount}
          />
        )}
        {currentStep === "dateTicketPicker" && order.selected_activities?.length > 0 && (
          <DateTicketPicker
            selectedActivies={order.selected_activities}
            handleChangeStep={handleChangeStep}
            order={order}
            setOrder={setOrder}
            pinnedDiscount={pinnedDiscount}
          />
        )}
        {currentStep === "payment" && (
          <Payment
            order={order}
            activity={order.selected_activities[0]}
            stripeAccountId={stripeVerification.accountId}
            handleChangeStep={handleChangeStep}
            pinnedDiscount={pinnedDiscount}
          />
        )}
        {currentStep === "groupBooking" && (
          <GroupBookings activities={openedActivities} handleChangeStep={handleChangeStep} />
        )}
        {currentStep === "cancelBooking" && (
          <ManageBookings activities={openedActivities} handleChangeStep={handleChangeStep} />
        )}
        {!singleActivityPlatform?.hidePoweredBy && (
          <Poweredby color={widget?.style?.colors?.textColorOnBackground} />
        )}
      </Suspense>
    </WidgetContainer>
  )
}

export default LandingPage
